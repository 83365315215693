import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import ScrollToTopLink from "../ScrollToTopLink";

const Conductor = () => {
  const { t } = useTranslation();
  return (
    <section className="section lb bt littlepad mousenormal">
      <div className="container">
        <div className="section-title text-center">
          <h4 style={{ color: "#131313" }}>{t("nav.conductor")}</h4>
        </div>

        <div className="row align-items-center">
          <div className="col-md-5 text-center">
            <div className="screen-normal wow slideInLeft">
              <img
                src="https://res.cloudinary.com/orchestrabusoni/image/upload/v1647612096/foto-di-F.Parenzan-8_halpwx.jpg"
                alt="Massimo Belli"
                className="img-fluid maestropic"
              />
            </div>
          </div>

          <div className="col-md-7 mobmartop30">
            <div className="tagline-v2 padleft30">
              <h3 className="uppercasebold1">massimo belli</h3>
              <p>{t("home.conductor_body")}</p>
              <ScrollToTopLink
                to="/conductor"
                className="btn btn-orange withradius secbtn withicon"
              >
                {t("home.news_more_button")}{" "}
                <FontAwesomeIcon icon={faLongArrowRight} />
              </ScrollToTopLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Conductor;
