import { useTranslation } from "react-i18next";
import { t } from "i18next";
import ScrollToTopLink from "../../ScrollToTopLink";

const HomeNewsCards = ({ newsItem, formatDate }) => {
  const { i18n } = useTranslation();
  const lang = i18n.language.substring(0, 2); // Get the language code (e.g., 'en', 'it')
  const title = lang === "en" ? newsItem.title_en : newsItem.title_it; // Get the title based on the language
  const body = lang === "en" ? newsItem.body_en : newsItem.body_it; // Get the body based on the language

  return (
    <div className="col-lg-4 reducewidth">
      <div className="blog-grid">
        <div className="blog-img">
          <div className="date text-uppercase">{formatDate(newsItem.date)}</div>
          <ScrollToTopLink to={`/news/${newsItem.id}`}>
            <img
              src={newsItem.img}
              title={title}
              alt={title}
              className="hoverzoom"
              width={"100%"}
            />
          </ScrollToTopLink>
        </div>
        <div className="blog-info">
          <h5 className="text-capitalize">
            <ScrollToTopLink to={`/news/${newsItem.id}`}>
              {title}
            </ScrollToTopLink>
          </h5>
          {body && (
            <div>{body.split(" ").slice(0, 20).join(" ") + "..."}</div>
          )}
          <div className="btn-bar">
            <ScrollToTopLink
              to={`/news/${newsItem.id}`}
              className="px-btn-arrow"
            >
              <span>{t("home.news_more_button")}</span>
              <i className="arrow"></i>
            </ScrollToTopLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeNewsCards;
