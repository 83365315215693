import { useParams } from "react-router-dom";
import data from "../../data/news.json";
import { useTranslation } from "react-i18next";
import Welcome from "./details/Welcome";
import Body from "./details/Body";

const NewsDetails = ({ formatDate, renderBody }) => {
  const { id } = useParams();
  const newsItem = data.find((obj) => obj.id === id);
  const { i18n } = useTranslation();

  // Determine which language to use
  const lang = i18n.language.substring(0, 2); // Get the language code (e.g., 'en', 'it')

  // Get the title and body based on the language
  const title = lang === "en" ? newsItem.title_en : newsItem.title_it;
  const body = lang === "en" ? newsItem.body_en : newsItem.body_it;

  return (
    <>
      <Welcome date={newsItem.date} formatDate={formatDate} title={title} />
      <Body
        newsItem={newsItem}
        body={body}
        title={title}
        renderBody={renderBody}
      />
    </>
  );
};

export default NewsDetails;
