import { useTranslation } from "react-i18next";
import musicians from "../data/soloists.json";

const Soloists = () => {
  const { t } = useTranslation();
  return (
    <section className="section">
      <div className="container">
        <div className="row text-center">
          {Object.keys(musicians).map((instrument) => (
            <div key={instrument} className="col-sm-6 instrument-section">
              <h4 className="header">{t(`concerts.${instrument}`)}</h4>
              <p className="mb-4">{musicians[instrument].join(", ")}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Soloists;
