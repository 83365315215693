const Label = ({ season, label, t }) => {
  return (
    <>
      {season !== "open_reh" && season !== "event" && (
        <div className="event_label">
          <div>
            <h6 className="text-capitalize">{t(`concerts.${label}`)}</h6>
          </div>
        </div>
      )}
    </>
  );
};

export default Label;
