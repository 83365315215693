import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import ScrollToTopLink from "../ScrollToTopLink";
import ComingSoon from "../ComingSoon";
import ConcertCards from "../cards/concert/ConcertCards";

const UpcomingEvents = ({ concerts, sectionRef }) => {
  const { t } = useTranslation();
  const currentDate = new Date();
  
  const upcomingConcerts = concerts
    .filter((concert) => new Date(concert.date) >= currentDate)
    .sort((a, b) => new Date(a.date) - new Date(b.date)) // Sort concerts by date in ascending order
    .slice(0, 3); // Take only the first 3

  return (
    <section ref={sectionRef} className="section">
      <div className="container">
        <div className="section-title text-center">
          <h4 style={{ color: "#131313" }}>{t("home.whats_on")}</h4>
          <h1 className="uppercasebold1">{t("home.next_events")}</h1>
        </div>
        <div className="row">
          {upcomingConcerts.length > 0 ? (
            <ConcertCards concerts={upcomingConcerts} />
          ) : (
            <ComingSoon />
          )}
        </div>
        <div className="text-center">
          <hr className="invis1" />
          <ScrollToTopLink to="/concerts" className="concertcalendarbtn">
            <FontAwesomeIcon icon={faCalendar} /> {t("home.concert_calendar")}{" "}
          </ScrollToTopLink>
        </div>
      </div>
    </section>
  );
};

export default UpcomingEvents;
