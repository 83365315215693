import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Cds = ({ cds }) => {
  const { t } = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const homecds = cds.slice(0, 5);

  return (
    <section className="section">
      <div className="container">
        <div className="section-title text-center">
          <h4 style={{ color: "#131313" }}>{t("nav.publications")}</h4>
          <h1 className="uppercasebold1">{t("home.last_cds")}</h1>
        </div>
        <Slider {...settings}>
          {homecds.map(({ id, buy_link, img, title }) => (
            <div className="cds reducewidth" key={id}>
              <div className="portfolio-ver-01">
                <div className="media-element">
                  {buy_link && (
                    <Link to={buy_link}>
                      <img
                        src={img}
                        alt={title}
                        className="img-fluid rounded_img"
                      />
                    </Link>
                  )}
                  {!buy_link && (
                    <Link to="/discography">
                      <img
                        src={img}
                        alt={title}
                        className="img-fluid rounded_img"
                      />
                    </Link>
                  )}
                </div>
                <div className="portfolio-details">
                  {buy_link ? (
                    <Link to={buy_link}>
                      <h4>
                        <span className="text-capitalize boss_title">
                          {title}
                        </span>
                      </h4>
                    </Link>
                  ) : (
                    <h4>
                      <span className="text-capitalize boss_title">
                        {title}
                      </span>
                    </h4>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Cds;
