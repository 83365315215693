import Magazines from "./Magazines";
import Books from "./Books";

const MagazinesBooks = ({ renderBody }) => {
  return (
    <section className="section">
      <Magazines renderBody={renderBody} />
      <Books renderBody={renderBody} />
    </section>
  );
};

export default MagazinesBooks;
