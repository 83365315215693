import { useTranslation } from "react-i18next";

const Curriculum = ({ renderBody }) => {
  const { t } = useTranslation();
  return (
    <section className="section">
      <div className="container text-justify">{renderBody(t("cv.body"))}</div>
    </section>
  );
};

export default Curriculum;
