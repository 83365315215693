import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMousePointer } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import contacts from "../data/contacts.json";

const Contacts = () => {
  const { t } = useTranslation();

  return (
    <section className="section">
      <div className="container text-center">
        {contacts.map((contact, index) => (
          <div key={index}>
            <h4 className="text-capitalize newstitle mt-5">
              {t(`contacts.${contact.title}`)}
            </h4>
            <p>
              {contact.name && !contact.link && (
                <>
                  {contact.name} <br />
                </>
              )}
              {contact.telFax && (
                <>
                  {" "}
                  {t("contacts.tel_fax")}: {contact.telFax} <br />
                </>
              )}
              {contact.cell && (
                <>
                  {" "}
                  {t("contacts.cell")}: {contact.cell} <br />
                </>
              )}
              {contact.email && (
                <>
                  {" "}
                  E-mail:{" "}
                  <a href={`mailto:${contact.email}`}>{contact.email}</a> <br />
                </>
              )}
              {contact.link && (
                <>
                  <a href={contact.link}>
                    <FontAwesomeIcon icon={faMousePointer} />
                  </a>{" "}
                  {contact.name}
                </>
              )}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Contacts;
