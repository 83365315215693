const ExtraArtist = ({ concert, t, i18n }) => {
  return (
    <>
      {concert.extra_artist_img && (
        <div className="card mb-3" style={{ border: "none" }}>
          <div className="row g-0 p-3">
            <div className="col-md-4 text-center">
              <img
                src={concert.extra_artist_img}
                className="rounded_img"
                width={"60%"}
                alt=""
              />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <p
                  className="card-text pt-5 pb-5"
                  style={{ fontSize: "1.1rem" }}
                >
                  {i18n.language === "en"
                    ? concert.extra_artist_en
                    : concert.extra_artist_it}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ExtraArtist;
