import { useEffect, useState } from "react";

const Table = ({ selectedYear, setYears, t, formatDate }) => {
  const [concerts, setConcerts] = useState([]);

  useEffect(() => {
    const fetchAvailableYears = async () => {
      try {
        const context = require.context(
          "../../data/concerts/",
          false,
          /\.json$/
        );
        const years = context
          .keys()
          .map((filename) => filename.match(/(\d+)\.json$/)[1]);
        setYears(years.sort((a, b) => b - a)); // Sort in descending order
      } catch (error) {
        console.error("Error fetching available years:", error);
      }
    };

    fetchAvailableYears();
  }, [setYears]);

  useEffect(() => {
    const loadConcerts = async () => {
      try {
        const response = await import(
          `../../data/concerts/${selectedYear}.json`
        );
        const allConcerts = response.default;

        const now = new Date();
        const expiredConcerts = allConcerts.filter((concert) => {
          const concertDate = new Date(concert.date);
          return concertDate < now;
        });

        setConcerts(expiredConcerts);
      } catch (error) {
        console.error(
          `Error loading concerts for year ${selectedYear}:`,
          error
        );
      }
    };

    loadConcerts();
  }, [selectedYear]);

  // Group concerts by season
  const groupedConcerts = concerts.reduce((acc, concert) => {
    const { season } = concert;
    if (!acc[season]) {
      acc[season] = [];
    }
    acc[season].push(concert);
    return acc;
  }, {});

  const getTitle = (season) => {
    switch (season) {
      case "fest_beeth":
        return t("archive.fest_beeth");
      case "mmi":
        return t("archive.mmi");
      case "open_reh":
        return t("archive.open_reh");
      case "event":
        return t("archive.event");
      case "fest_lavoro":
        return t("archive.fest_lavoro");
      case "mus_rac":
        return t("archive.mus_rac");
      case "scuola_55":
        return t("archive.scuola_55");
      default:
        return season;
    }
  };

  return (
    <div className="container mt-5">
      {Object.keys(groupedConcerts).length > 0 ? (
        Object.keys(groupedConcerts).map((season) => (
          <div key={season}>
            <h4 className="newstitle mt-5">{getTitle(season)}</h4>
            <table className="table">
              <thead>
                <tr>
                  <th>{t("archive.date_time_place")}</th>
                  <th>{t("archive.title")}</th>
                  <th>{t("archive.artists")}</th>
                </tr>
              </thead>
              <tbody>
                {groupedConcerts[season].map((concert) => (
                  <tr key={concert.id}>
                    <td className="narrow">
                      {`${formatDate(concert.date)}, ${concert.time}`}
                      <br />
                      <small>{concert.place}</small>
                    </td>
                    <td className="text-capitalize">{concert.title}</td>
                    <td>
                      {concert.artists
                        .map((artist) => {
                          if (artist.name === "orchestra_busoni") {
                            return t(`concerts.${artist.name}`);
                          } else {
                            return artist.name;
                          }
                        })
                        .join(", ")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))
      ) : (
        <p>{t("archive.no_expired_concerts")}</p>
      )}
    </div>
  );
};

export default Table;
