import { useTranslation } from "react-i18next";

const About = ({ renderBody }) => {
  const { t } = useTranslation();
  return (
    <section className="section">
      <div className="container text-justify">
        {renderBody(t("about.intro"))}
      </div>
      <div className="container">
        <h4 className="text-capitalize newstitle mt-5">aldo belli</h4>
        <div className="row mt-5">
          <div className="col-md text-center">
            <img
              src="https://res.cloudinary.com/orchestrabusoni/image/upload/v1647612072/AldoBelli_r3ivxl.jpg"
              alt="Aldo Belli"
              className="rounded_img"
              width={"80%"}
            />
          </div>
          <div className="col-md text-justify mt-3">
            {renderBody(t("about.aldo_belli_bio"))}
          </div>
        </div>
      </div>

      <div className="container">
        <h4 className="text-capitalize newstitle mt-5">
          {t("about.pic_title")}
        </h4>
        <div className="row mt-2">
          <div className="col-sm">
            <img
              src="https://res.cloudinary.com/orchestrabusoni/image/upload/v1647612101/slide77_BusoniOld_IstitutoGermanico0_iwfuva.png"
              alt="Orchestra Busoni"
              className="mt-3 rounded_img"
              width={"100%"}
            />
          </div>
          <div className="col-sm">
            <img
              src="https://res.cloudinary.com/orchestrabusoni/image/upload/v1647612106/slide77_BusoniOld_IstitutoGermanico1_spccz9.png"
              alt="Orchestra Busoni"
              className="mt-3 rounded_img"
              width={"100%"}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
