import React from "react";
import board from "../../data/board.json"; // Adjust the path as necessary

const Intro = ({ t }) => {
  return (
    <div className="container">
      <div className="text-justify mb-5">{t("transparency.intro_1")}</div>
      <h4 className="header">{t("transparency.governing_body")}</h4>
      <table className="transparent-table mb-3">
        <tbody>
          {board.map((member, index) => (
            <tr key={index} className="boss_title">
              <td className="pr-5">{t(member.titleKey)}</td>
              <td>{member.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-justify mb-5">{t("transparency.intro_2")}</div>
      <h4 className="text-capitalize header">
        {t("transparency.advertising_obligations")}
      </h4>
      <div className="text-justify mb-5">{t("transparency.intro_3")}</div>
    </div>
  );
};

export default Intro;
