import reviews from "../data/reviews.json";
import RevCard from "./cards/RevCard";

const Reviews = () => {
  return (
    <section className="section">
      <div className="container text-center">
        <RevCard reviews={reviews} />
      </div>
    </section>
  );
};

export default Reviews;
