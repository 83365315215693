import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";

const TestCard = ({ testimonials }) => {
  const { t } = useTranslation();
  return (
    <div className="row">
      <div className="col-md-10 offset-md-1">
        {testimonials.map(({ id, image, name, width }) => (
          <div
            key={id}
            className="testimonials-carousel testi-style-01 mb-4 clearfix"
          >
            <img
              src={image}
              alt={name}
              width={width}
              className="img-fluid rounded-circle float-left"
            />
            <h4 className="uppercasebold2">{name}</h4>
            <p className="testimonial_quote">
              <FontAwesomeIcon icon={faQuoteLeft} /> {t(`testimonials.${id}`)}{" "}
              <FontAwesomeIcon icon={faQuoteRight} />{" "}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestCard;
