import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import logo from "../../logo.png";
import Menu from "./Menu";

const Navbar = ({ languages, data }) => {
  const { t } = useTranslation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [openSubmenus, setOpenSubmenus] = useState({});

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDropdown = (index) => {
    setOpenSubmenus({ ...openSubmenus, [index]: !openSubmenus[index] });
  };

  const handleDropdownClick = () => {
    setIsMobileMenuOpen(false); 
    setOpenSubmenus({});
  };

  const handleSubmenuItemTouch = (event) => {
    // Check if it's a top-level item click
    if (event.target.closest(".jstmobile, .nav-link")) return;
      
    // Stop event propagation to prevent mobile menu toggle
    event.stopPropagation();
    setIsMobileMenuOpen(false);
    setOpenSubmenus({});
  };

  return (
    <header
      className={`header graded-header ${
        isMobileMenuOpen ? "mobile-menu-open" : ""
      }`}
    >
      <div className="container">
        <nav
          className="navbar navbar-toggleable-md navbar-inverse yamm"
          id="slide-nav"
        >
          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            onClick={handleMobileMenuToggle}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="" className="navbar-logo" />
          </Link>
          <div
            className={`collapse navbar-collapse ${
              isMobileMenuOpen ? "show" : ""
            }`}
            id="navbarTopMenu"
          >
            <ul className="navbar-nav mr-auto mt-2 mt-md-0">
              <li className="jstmobile nav-link home">
                <Link to="/" onClick={handleDropdownClick}>
                  Homepage
                </Link>
              </li>
              <li className="nav-item dropdown has-submenu">
                <span
                  className={`nav-link dropdown-toggle ${
                    openSubmenus[1] ? "show" : ""
                  }`}
                  onClick={() => toggleDropdown(1)}
                >
                  {t("nav.who_we_are")}
                </span>
                {openSubmenus[1] && (
                  <ul className="dropdown-menu show">
                    <Menu
                      data={data}
                      handleSubmenuItemTouch={handleSubmenuItemTouch}
                      submenu="who"
                    />
                  </ul>
                )}
              </li>
              <li className="nav-item dropdown has-submenu">
                <span
                  className={`nav-link dropdown-toggle ${
                    openSubmenus[2] ? "show" : ""
                  }`}
                  onClick={() => toggleDropdown(2)}
                >
                  {t("nav.publications")}
                </span>
                {openSubmenus[2] && (
                  <ul className="dropdown-menu show">
                    <Menu
                      data={data}
                      handleSubmenuItemTouch={handleSubmenuItemTouch}
                      submenu="pub"
                    />
                  </ul>
                )}
              </li>
              <li className="nav-item dropdown has-submenu">
                <span
                  className={`nav-link dropdown-toggle ${
                    openSubmenus[3] ? "show" : ""
                  }`}
                  onClick={() => toggleDropdown(3)}
                >
                  {t("nav.activity")}
                </span>
                {openSubmenus[3] && (
                  <ul className="dropdown-menu show">
                    <Menu
                      data={data}
                      handleSubmenuItemTouch={handleSubmenuItemTouch}
                      submenu="act"
                    />
                  </ul>
                )}
              </li>
              <li className="nav-item dropdown has-submenu">
                <span
                  className={`nav-link dropdown-toggle ${
                    openSubmenus[4] ? "show" : ""
                  }`}
                  onClick={() => toggleDropdown(4)}
                >
                  {t("nav.transparency")}
                </span>
                {openSubmenus[4] && (
                  <ul className="dropdown-menu show">
                    <Menu
                      data={data}
                      handleSubmenuItemTouch={handleSubmenuItemTouch}
                      submenu="trasp"
                    />
                  </ul>
                )}
              </li>
              <li>
                <Link
                  className="nav-link"
                  to="/contacts"
                  onClick={handleDropdownClick}
                >
                  {t("nav.contacts")}
                </Link>
              </li>
              <li className="jstmobile text-uppercase">
                {languages.map((language) => (
                  <Link
                    key={language.country_code}
                    onClick={() => {
                      i18next.changeLanguage(language.code);
                      handleDropdownClick();
                    }}
                  >
                    {language.code}{" "}
                  </Link>
                ))}
              </li>
            </ul>
            <div className="nav navbar-nav ml-auto hidden-md-down hidden-sm-down hidden-xs-down">
              <div className="head-social">
                <a
                  href="https://it-it.facebook.com/orchestraferrucciobusoni"
                  target="_blank"
                  rel="noreferrer"
                  title="Facebook"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a
                  href="https://www.instagram.com/orchestra_busoni/"
                  target="_blank"
                  rel="noreferrer"
                  title="Instagram"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </div>
              <div className="menu-right text-uppercase">
                {languages.map((language) => (
                  <Link
                    key={language.country_code}
                    onClick={() => {
                      i18next.changeLanguage(language.code);
                      handleDropdownClick();
                    }}
                  >
                    {language.code}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
