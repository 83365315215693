const Welcome = ({ date, formatDate, title }) => {
  return (
    <header className="hero concert1505 align-bottom fiftyvh">
      <div className="magnifier grd3"></div>
      <div className="container concertintro text-left">
        <h3 className="text-capitalize news_details_title">{title}</h3>
        <h4>{formatDate(date)}</h4>
      </div>
    </header>
  );
};

export default Welcome;
