import Tables from "./Tables";
import BoardCv from "./BoardCv";
import cvData from "../../data/transparency/board_cv.json";
import { useTranslation } from "react-i18next";
import Intro from "./Intro";

const Transparency = ({ formatDate, renderBody }) => {
  const { t } = useTranslation();
  return (
    <>
      <section className="section">
        <Intro t={t} />

        <div className="container">
          <h4 className="header"> {t("transparency.curriculum_board")}</h4>
          <p>{t("transparency.intro_4")}</p>
          {cvData.map((member) => (
            <BoardCv key={member.id} member={member} renderBody={renderBody} />
          ))}
        </div>

        <Tables formatDate={formatDate} />
      </section>
    </>
  );
};

export default Transparency;
