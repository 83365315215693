import { useTranslation } from "react-i18next";

const Books = ({ renderBody }) => {
  const { t } = useTranslation();
  return (
    <div className="container mt-5">
      <h3 className="newstitle text-capitalize">{t("magazines.books")}</h3>
      <div className="row mt-5">
        <div className="col-sm">
          <div className="portfolio-ver-01">
            <div className="text-right">
              <img
                src="https://res.cloudinary.com/orchestrabusoni/image/upload/v1647612079/clip_image002_u4fmbl.jpg"
                alt="Oggi come mezzo secolo fa"
                className="rounded_img"
                width={"50%"}
              />
            </div>
          </div>
        </div>
        <div className="col-lg">
          <div className="portfolio-ver-01">
            <div className="portfolio-details">
              <h4 className="mt-5">
                <span className="text-capitalize cdtitle">
                  1965 - 2015 <br />
                  Oggi come mezzo secolo fa
                </span>
              </h4>
              {t("magazines.books_intro")}
            </div>
          </div>
        </div>
        <div className="margintop2em text-justify">
          {renderBody(t("magazines.books_body"))}
        </div>
      </div>
    </div>
  );
};

export default Books;
