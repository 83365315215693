import { useState } from "react";
import transparencyData from "../../data/transparency/funds.json";
import { useTranslation } from "react-i18next";
import SelectYear from "../input/SelectYear";
import Table from "./Table";

const Tables = ({ formatDate }) => {
  const { t } = useTranslation();
  const [selectedYear, setSelectedYear] = useState("2023");

  return (
    <>
      <div className="container mt-5">
        <h4 className="header"> {t("transparency.received_funds")}</h4>
        <div className="text-justify">{t("transparency.funds_intro")}</div>
      </div>
      <div className="container mt-3">
        <SelectYear
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          t={t}
          years={Object.keys(transparencyData)}
        />
        <div className="text-justify mb-2">
          {t("transparency.funds_table")} {selectedYear}:
        </div>
        <Table
          transparencyData={transparencyData}
          selectedYear={selectedYear}
          t={t}
          formatDate={formatDate}
        />
      </div>
    </>
  );
};

export default Tables;
