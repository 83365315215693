const SelectYear = ({ setSelectedYear, t, years, selectedYear }) => {
  return (
    <div className="row pb-5">
      <div className="col-md-auto text-center">
        <div className="dropdown">
          <label htmlFor="yearSelect" className="form-label d-block">
            {t("archive.select_year")}:
          </label>
          <select
            id="yearSelect"
            value={selectedYear}
            onChange={(e) => setSelectedYear(parseInt(e.target.value))}
            className="btn btn-secondary dropdown-toggle custom-select-year"
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default SelectYear;
