import React from 'react';
import ConcertCards from "../cards/concert/ConcertCards";

const Concerts = ({ concerts }) => {
  const currentDate = new Date(); // Get current date
  const upcomingConcerts = concerts.filter(concert => new Date(concert.date) >= currentDate); // Filter concerts based on expiration
  const pastConcerts = concerts.filter(concert => new Date(concert.date) < currentDate);

  // Sort upcoming concerts based on date in ascending order
  const sortedUpcomingConcerts = [...upcomingConcerts].sort((a, b) => new Date(a.date) - new Date(b.date));

  // Sort past concerts based on date in descending order
  const sortedPastConcerts = [...pastConcerts].sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <section className="section mousenormal concertcalendar">
      <div className="container">
        <div className="row">
          <ConcertCards concerts={sortedUpcomingConcerts} />
        </div>
        {sortedPastConcerts.length > 0 && (
          <div className="past_concerts">
            <hr className="invis1" />
            <hr className="invis2" />
            <h2 className="uppercasebold2 text-center">Past concerts</h2>
            <div className="row">
              <ConcertCards concerts={sortedPastConcerts} />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Concerts;
