import Label from "./Label";

const UpperDetails = ({ concert: { month, day, season, label }, t }) => {
  return (
    <>
      <Label season={season} label={label} t={t} />
      <h6 className="uppercasebold1">{t(`concerts.${month}`)}</h6>
      <h3 className="uppercasebold1">{day}</h3>
    </>
  );
};

export default UpperDetails;
