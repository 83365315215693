import ScrollToTopLink from "../../ScrollToTopLink";
import BuyButton from "../../buttons/BuyButton";
import MoreButton from "../../buttons/MoreButton";

const Details = ({
  concert: { id, title, place, time, info_button, buy_link, },
  t,
  isExpired,
}) => {
  return (
    <>
      <div className="service-desc-01">
        <h5>
          <ScrollToTopLink
            to={`/concerts/${id}`}
            className="special_hover text-capitalize"
          >
            {title}
          </ScrollToTopLink>
        </h5>

        <h6 className="uppercasebold2">{place}</h6>
        <h6 className="uppercasebold2">{time}</h6>

        {!isExpired && (
          <div className="portfolio-meta">
            <div className={`${buy_link ? "float-left" : "text-center"} `}>
              <MoreButton
                to={`/concerts/${id}`}
                text={t(`concerts.${info_button}`)}
              />
            </div>
            {buy_link && (
              <div className="float-right">
                <BuyButton href={buy_link} text={t("concerts.buy_btn")} />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Details;
