import ScrollToTopLink from "../ScrollToTopLink";

const MoreButton = ({ to, text }) => {
  return (
    <ScrollToTopLink
      to={to}
      className="text-capitalize info_button"
    >
      {text}
    </ScrollToTopLink>
  );
};

export default MoreButton;
