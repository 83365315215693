import { useTranslation } from "react-i18next";
import NewsButton from "../../buttons/NewsButton";
import DownloadButton from "../../buttons/DownloadButton";
import YouTubePlayer from "../../YouYubePlayer";

const Body = ({
  body,
  renderBody,
  title,
  newsItem: {
    img_in_details,
    img,
    img_width,
    img_by,
    button_url,
    download_button_url,
    video_id,
  },
}) => {
  const { t } = useTranslation();

  return (
    <section className="section">
      {body && (
        <div className="container">
          <div className="text-justify">{renderBody(body)}</div>
        </div>
      )}
      {img_in_details !== "n" && (
        <div className="container text-center mt-5">
          <img
            src={img}
            alt={title}
            width={img_width}
            className="rounded_img"
          />
          <label htmlFor="img" className="image-label">
            <small>{img_by}</small>
          </label>
        </div>
      )}
      {button_url && (
        <NewsButton url={button_url} text={t("concerts.info_button")} />
      )}
      {download_button_url && (
        <DownloadButton
          href={download_button_url}
          download={title}
          text={t("concerts.download_button")}
        />
      )}
      {video_id && (
        <div className="container text-center">
          <YouTubePlayer video_id={video_id} />
        </div>
      )}
    </section>
  );
};

export default Body;
