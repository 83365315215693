const Table = ({ transparencyData, selectedYear, t, formatDate }) => {
  return (
    <div className="container">
      <table className="table">
        <thead>
          <tr className="text-capitalize">
            <th>{t("transparency.date")}</th>
            <th>{t("transparency.subject")}</th>
            <th>{t("transparency.law")}</th>
            <th>{t("transparency.amount")}</th>
          </tr>
        </thead>
        <tbody>
          {transparencyData[selectedYear].data.map((entry, index) => (
            <tr key={index}>
              <td className="narrow">{formatDate(entry.data)}</td>
              <td className="text-capitalize narrow">{entry.ente}</td>
              <td className="text-capitalize">{entry.legge}</td>
              <td>{entry.importo}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
