import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";

const RevCard = ({ reviews }) => {
  return (
    <div className="row">
      {reviews.map(({ title, content, author, source, source_url }, index) => (
        <div key={index} className="col-md-10 review">
          <div className="clearfix">
            {title !== "Title Not Found" && (
              <h4 className="uppercasebold2">{title}</h4>
            )}{" "}
            <p className="mt-5 text-justify">
              <FontAwesomeIcon icon={faQuoteLeft} /> {content}{" "}
              <FontAwesomeIcon icon={faQuoteRight} />
            </p>
            <h6 className="newsdatum mb-3">
              {author}{" "}
              {source_url !== "URL Not Found" && (
                <a href={source_url}>{source}</a>
              )}
            </h6>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RevCard;
