import { useTranslation } from "react-i18next";
import HomeNewsCards from "../cards/news/HomeNewsCards";

const SupportAndNews = ({news, formatDate}) => {
  const { t } = useTranslation();
  return (
    <section className="section">
      <div className="container">
        <div className="row messagebox">
          <div className="col-md-10">
            <div className="message-box absolute-widget text-center lightcolor drkorg">
              <h3>{t("home.support_title")}</h3>
              <p>{t("home.support_body")}</p>
            </div>
          </div>
        </div>
        <hr className="invis2" />
        <div className="section-title text-center">
          <h4 style={{ color: "#131313" }}>{t("nav.news")}</h4>
          <h1 className="uppercasebold1">{t("home.latest_updates")}</h1>
        </div>

        <div className="row">
          {news
            .map((newsItem) => (
              <HomeNewsCards
                key={newsItem.id}
                newsItem={newsItem}
                formatDate={formatDate}
              />
            ))
            .slice(0, 3)}
        </div>
      </div>
    </section>
  );
};

export default SupportAndNews;
