import { useTranslation } from "react-i18next";

const PrivacyPolicy = ({ renderBody }) => {
  const { t } = useTranslation();
  return (
    <section className="section">
      <div className="container">
        <h4 className="header">disclaimer</h4>
        <div className="text-justify">{t("privacy.par1")}</div>
        <h4 className="header">{t("privacy.tit2")}</h4>
        <div className="text-justify">{t("privacy.par2")} </div>
        <h4 className="header">{t("privacy.tit3")}</h4>
        <div className="text-justify">{t("privacy.par3")}</div>
        <h4 className="header">{t("privacy.tit4")} </h4>
        <div className="text-justify">{renderBody(t("privacy.par4"))}</div>
        <h4 className="header">{t("privacy.tit6")}</h4>
        <div className="text-justify">{renderBody(t("privacy.par6"))}</div>
        <span className="uppercasebold1 text-center">{t("privacy.tit5")}</span>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
