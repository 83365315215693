import React, { useEffect, useRef } from 'react';

const YouTubePlayer = ({ video_id }) => {
  const playerRef = useRef(null);

  useEffect(() => {
    // Function to create the YouTube player
    const createPlayer = () => {
      playerRef.current = new window.YT.Player('player', {
        videoId: video_id,
        playerVars: {
          controls: 1, // Hide controls
          autoplay: 1, // Auto-play the video
        },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    };

    // Load the YouTube IFrame Player API if not already loaded
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }

    // This function will be called when the video player is ready
    const onPlayerReady = (event) => {
      console.log('Player is ready');
      // Optionally start the video here
      event.target.playVideo();
    };

    // This function will be called when the player's state changes
    const onPlayerStateChange = (event) => {
      console.log('Player state changed:', event.data);
    };

    return () => {
      // Clean up the YouTube player when the component is unmounted
      if (playerRef.current) {
        playerRef.current.stopVideo();
        playerRef.current = null;
      }
    };
  }, [video_id]); // Dependency array to re-run effect if video_id changes

  return (
    <div>
      <div id="player" style={{ width: '1120px', height: '630px' }}></div>
    </div>
  );
};

export default YouTubePlayer;
