import Artists from "./Artists";
import Map from "./Map";
import Program from "./Program";
import Sponsors from "./Sponsors";

const Body = ({ concert, t, i18n, renderBody, isExpired }) => {
  return (
    <section className="section">
      <div className="container">
        <div className="text-justify">
          {renderBody(
            i18n.language === "en" ? concert.intro_en : concert.intro_it
          )}
        </div>
        <Artists concert={concert} t={t} i18n={i18n} />
        <Program concert={concert} t={t} />
      </div>
      {!isExpired && <Map map_link={concert.map_link} t={t} />}
      <Sponsors
        sponsor_img={concert.sponsor_img}
        sponsor_img_width={concert.sponsor_img_width}
        t={t}
      />
    </section>
  );
};

export default Body;
