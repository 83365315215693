import React from "react";
import { useLocation } from "react-router-dom";
import Welcome from "./Welcome"; // Assuming Welcome is in components folder

const ConditionalWelcome = () => {
  const location = useLocation();

  // Define paths where ConditionalWelcome should not be rendered
  const excludedPaths = ["/", /^\/concerts\/\d+$/, /^\/news\/\d+$/]; // Using regex to match /concerts/:id and /news/:id

  // Check if current path is not in the excluded paths
  const shouldRenderWelcome = !excludedPaths.some((path) =>
    typeof path === "string"
      ? location.pathname === path
      : path.test(location.pathname)
  );

  return shouldRenderWelcome && <Welcome page={location.pathname} />;
};

export default ConditionalWelcome;
