import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";

const DownloadButton = ({ text, download, href }) => (
  <div className="text-center mt-5">
    <a
      href={href}
      download={download}
      target="_blank"
      rel="noreferrer"
      className="button-download"
    >
      <FontAwesomeIcon icon={faFileInvoice} className="me-3" /> {text}
    </a>
  </div>
);

export default DownloadButton;
