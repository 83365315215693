import React from "react";
import { Link } from "react-router-dom";

class ScrollToTopLink extends React.Component {
  handleClick = () => {
    // Add a small delay before scrolling to the top
    setTimeout(() => {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Smooth scrolling animation
      });
    }, 100); // Adjust delay as needed

    // Call handleSubmenuItemTouch if it's a top-level item
    //FIXME: If it's written "this.props.istoplevel" it works but it generates the error "Uncaught TypeError: this.props.handleSubmenuItemTouch is not a function". If it's written "this.props.handleSubmenuItemTouch" it does not generate the error but the menu does not retract on mobile
    if (this.props.istoplevel) {
      // if (this.props.istoplevel === "true" && this.props.handleSubmenuItemTouch) {
      this.props.handleSubmenuItemTouch();
    }
  };

  render() {
    return (
      <Link {...this.props} onClick={this.handleClick}>
        {this.props.children}
      </Link>
    );
  }
}

export default ScrollToTopLink;
