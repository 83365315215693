import TestCard from "./TestCard";

const Testimonials = ({ testimonials }) => {
  return (
    <section className="section">
      <div className="container">
        <TestCard testimonials={testimonials} />
      </div>
    </section>
  );
};

export default Testimonials;
