import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <section className="section">
      <div className="container notfound">
        <div className="p-4 hide-on-mobile">
          <FontAwesomeIcon icon={faExclamationTriangle} size="8x" color="red" />
        </div>
        <div className="font1and2rem">
          <div>{t("notfound.description")}</div>
          <div className="mt-2">
            {t("notfound.goto")}{" "}
            <Link to="/" className="red">
              {t("notfound.homepage")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
