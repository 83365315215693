import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";

const Carousel = ({ testimonials }) => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <section className="section lb mousenormal">
      <div className="container">
        <div className="section-title text-center">
          <h4 style={{ color: "#131313" }}>{t("nav.testimonials")}</h4>
        </div>
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <Slider {...settings}>
              {testimonials.map((testimonial) => (
                <div
                  key={testimonial.id}
                  className="testimonials-carousel testi-style-01 clearfix"
                >
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    width={testimonial.width}
                    className="img-fluid rounded-circle float-left"
                  />
                  <h4 className="uppercasebold2">{testimonial.name}</h4>
                  <p className="">
                    <FontAwesomeIcon icon={faQuoteLeft} />{" "}
                    {t(`testimonials.${testimonial.id}`)}{" "}
                    <FontAwesomeIcon icon={faQuoteRight} />{" "}
                  </p>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Carousel;
