const Map = ({ map_link, t }) => {
  return (
    <div className="container mt-5">
      <h4 className="text-capitalize titlenotaligned">
        {t("concerts.how_to_reach_us")}
      </h4>
      <iframe
        src={map_link}
        title="Map"
        width="100%"
        height="400px"
        className="map"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Map;
