import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker, faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faYoutube,
  faSpotify,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../logo.png";
import Menu from "./nav/Menu";
import ScrollToTopLink from "./ScrollToTopLink";

const Footer = ({ data }) => {
  const { t } = useTranslation();

  return (
    <footer className="footer section">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="widget">
              <ScrollToTopLink
                to="/"
                title="Nuova Orchestra da Camera Ferruccio Busoni Logo"
              >
                <img
                  src={logo}
                  className="footer-logo"
                  alt="Orchestra Busoni Logo"
                />
              </ScrollToTopLink>
              <h4 className="widget-title">Nuova orchestra da camera</h4>
              <h4 className="widget-title">"FERRUCCIO BUSONI"</h4>
            </div>
          </div>
          <div className="col-md-8 mobmartop30">
            <div className="widget">
              <div className="footer-links hide-on-mobile">
                <nav className="foot-link">
                  <ul className="text-capitalize">
                    <Menu data={data} submenu="footer" />
                  </ul>
                </nav>
              </div>

              <div className="widget">
                <div className="contact-widget">
                  <div className="row">
                    <div className="col-md-4">
                      <h4 className="widget-title">
                        <FontAwesomeIcon icon={faMapMarker} />{" "}
                        {t("footer.location")}
                      </h4>
                      <p>TRIESTE, {t("footer.country")}</p>
                    </div>

                    <div className="col-md-4">
                      <h4 className="widget-title">
                        <FontAwesomeIcon icon={faEnvelopeOpen} />{" "}
                        {t("footer.mail")}
                      </h4>
                      <Link
                        to="mailto:info@orchestrabusoni.it"
                        className="footer-mail"
                      >
                        info@orchestrabusoni.it
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 text-center">
            <div className="footer-social">
              <div>
                <Link
                  className="social-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                  itemProp="sameAs"
                  to="https://it-it.facebook.com/orchestraferrucciobusoni"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </Link>
                <Link
                  className="social-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                  itemProp="sameAs"
                  to="https://www.instagram.com/orchestra_busoni/"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </Link>
                <Link
                  className="social-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                  itemProp="sameAs"
                  to="https://www.youtube.com/channel/UCbGsd1bzwvsR37q1QQg34_A/featured"
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </Link>
                <Link
                  className="social-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                  itemProp="sameAs"
                  to="https://open.spotify.com/artist/0IAfy2AKm5Tt3a01WeRJjR/discography/album"
                >
                  <FontAwesomeIcon icon={faSpotify} />
                </Link>
              </div>
            </div>
            <div className="footer-copy">
              <p>
                ©2024 All Rights Reserved <br />
                Nuova Orchestra da Camera Ferruccio Busoni
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
