import dedications from "../data/dedications.json";

const Dedications = () => {
  return (
    <section className="section">
      <div className="container">
        <table className="table">
          <tbody>
            {dedications.map((dedication, index) => (
              <tr key={index}>
                <th scope="row">{dedication.composer}</th>
                <td>{dedication.piece}</td>
                <td>{dedication.dedication}</td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: dedication.date_location,
                  }}
                ></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Dedications;
