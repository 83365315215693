import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons";

const Welcome = ({
  concert: { date, time, title, place, buy_link, buy_btn },
  formatDate,
  t,
  isExpired,
}) => {
  return (
    <section className="hero concert1505 seventyvh">
      <div className="magnifier grd3"></div>
      <div className="container concertintro text-left">
        <h3>
          {formatDate(date)} {t("concerts.at")} {time}
        </h3>
        <h2 className="text-capitalize"> {title} </h2>
        <h5 className="uppercasebold2" style={{ color: "#fff" }}>
          {place}
        </h5>
        <hr className="invis1" />
        {!isExpired && buy_link && (
          <a href={buy_link} className="text-capitalize acqbuttonconcdet">
            <FontAwesomeIcon icon={faMoneyBillAlt} /> {t(`concerts.${buy_btn}`)}
          </a>
        )}
      </div>
    </section>
  );
};

export default Welcome;
