import { useState } from "react";
import { useTranslation } from "react-i18next";
import SelectYear from "../input/SelectYear";
import Table from "./Table";

const Archive = ({ formatDate }) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [years, setYears] = useState([]);
  const { t } = useTranslation();

  return (
    <section className="section">
      <div className="container">
        <SelectYear
          setSelectedYear={setSelectedYear}
          t={t}
          years={years}
          selectedYear={selectedYear}
        />
        <Table
          selectedYear={selectedYear}
          setYears={setYears}
          t={t}
          formatDate={formatDate}
        />
      </div>
    </section>
  );
};

export default Archive;
