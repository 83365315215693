import { useTranslation } from "react-i18next";
import BuyButton from "../buttons/BuyButton";

const ImgTextCard = ({
  id,
  img,
  img_width,
  title,
  details,
  buy_link,
  text_instead_of_buy_link,
  renderBody,
}) => {
  const { t } = useTranslation();

  const replacePlaceholders = (str, t) => {
    return str.replace(/\$t\(([^)]+)\)/g, (_, key) => t(key));
  };

  return (
    <div className="row cds" key={id}>
      <div className="col-sm">
        <div className="portfolio-ver-01">
          <div className="media-element text-center">
            <img
              src={img}
              alt={title}
              className="img-fluid rounded_img"
              width={`${img_width}%`}
            />
          </div>
        </div>
      </div>
      <div className="col-lg mb-5">
        <div className="portfolio-ver-01">
          <div className="portfolio-details">
            <h4>
              <span className="text-capitalize cdtitle">{title}</span>
            </h4>
            {renderBody(replacePlaceholders(details, t))}
          </div>
          {buy_link ? (
            <div className="portfolio-meta clearfix">
              <div className="float-right">
                <BuyButton href={buy_link} text={t("disc.buy_btn")} />
              </div>
            </div>
          ) : (
            <div className="portfolio-meta clearfix">
              <div className="float-right">
                <span>{text_instead_of_buy_link}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImgTextCard;
