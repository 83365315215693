import { useState } from "react";
import { useTranslation } from "react-i18next";

const BoardCv = ({ member, renderBody }) => {
  const { i18n } = useTranslation();
  const lang = i18n.language.substring(0, 2); // Get the language code (e.g., 'en', 'it')
  const body = lang === "en" ? member.body_en : member.body_it;

  const AccordionItem = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };

    return (
      <div className={`accordion-item ${isOpen ? "open" : ""}`}>
        <div className="accordion-header" onClick={toggleAccordion}>
          <h6 className="boss_title">{title} </h6>
        </div>
        <div className="accordion-content">
          {isOpen && <div className="p-1">{content}</div>}
        </div>
      </div>
    );
  };

  return (
    <AccordionItem
      title={member.name}
      content={<div className="text-justify">{renderBody(body)}</div>}
    />
  );
};

export default BoardCv;
