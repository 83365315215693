const FirstCard = ({ input, renderBody }) => {
  return (
    <>
      {input.map((item) => (
        <>
          <h5 className="text-capitalize boss_title">{item.composer}</h5>
          <div className="m-0">
          {renderBody(item.piece)}
          </div>
        </>
      ))}
    </>
  );
};

export default FirstCard;
