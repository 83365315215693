import { useTranslation } from "react-i18next";
import Image from "./Image";
import Details from "./Details";
import UpperDetails from "./UpperDetails";

const ConcertCard = ({ concert }) => {
  const { t } = useTranslation();
  const isExpired = new Date(concert.date) < new Date(); // Determine if the concert date has expired

  return (
    <div className="col-md-4 space reducewidth" key={concert.id}>
      <div
        className={`service-style-01 concertcardfirst${
          isExpired ? " done" : ""
        }`}
      >
        <UpperDetails concert={concert} t={t} />
        <Image concert={concert} />
        <Details concert={concert} t={t} isExpired={isExpired} />
      </div>
    </div>
  );
};

export default ConcertCard;
