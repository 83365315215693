import { useTranslation } from "react-i18next";
import ScrollToTopLink from "../../ScrollToTopLink";

const NewsCard = ({
  newsItem: { title_en, title_it, body_en, body_it, id, img, date },
  formatDate,
}) => {
  const { i18n } = useTranslation();
  const lang = i18n.language.substring(0, 2); // Get the language code (e.g., 'en', 'it')
  const title = lang === "en" ? title_en : title_it; // Get the title based on the language
  const body = lang === "en" ? body_en : body_it; // Get the body based on the language

  return (
    <ScrollToTopLink to={`/news/${id}`}>
      <div className="card mb-3 newscardhover">
        <div className="row g-0">
          {img !== "" && (
            <div className="col-md-4 text-center">
              <img
                src={img}
                className="img-fluid rounded-start rounded_img"
                alt={title}
                width={"70%"}
              />
            </div>
          )}

          <div className="col-md-8">
            <div className="card-body">
              <h4 className="newstitle text-left mmt-5">{title}</h4>
              <p className="card-text ">{formatDate(date)}</p>
              {body && (
                <p className="card-text">
                  {body.split(" ").slice(0, 40).join(" ") + " ..."}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </ScrollToTopLink>
  );
};

export default NewsCard;
