import { useRef } from "react";
import Welcome from "./Welcome";
import Cds from "./Cds";
import Conductor from "./Conductor";
import UpcomingEvents from "./UpcomingEvents";
import Who from "./Who";
import SupportAndNews from "./SupportAndNews";
import Carousel from "./Carousel";

const Home = ({ concerts, news, cds, testimonials, formatDate }) => {
  const sectionRef = useRef(null);

  return (
    <>
      <Welcome sectionRef={sectionRef} />
      <Cds cds={cds} />
      <Conductor />
      <UpcomingEvents concerts={concerts} sectionRef={sectionRef} />
      <Who />
      <SupportAndNews news={news} formatDate={formatDate} />
      <Carousel testimonials={testimonials} />
    </>
  );
};

export default Home;
