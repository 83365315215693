import { useTranslation } from "react-i18next";
import ScrollToTopLink from "../ScrollToTopLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";

const Who = () => {
  const { t } = useTranslation();
  return (
    <section className="section lb mousenormal" id="about">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <div className="screen-normal wow slideInLeft">
              <img
                src="https://res.cloudinary.com/orchestrabusoni/image/upload/v1650716828/promoimage_ioicvd.jpg"
                alt=""
                className="img-fluid rounded_img"
              />
            </div>
          </div>
          <div className="col-md-5 mobmartop30">
            <div className="tagline-v2 padleft30">
              <h3 className="uppercasebold1">{t("nav.who_we_are")}</h3>
              <p>{t("home.who_we_are_body")}</p>
              <ScrollToTopLink
                to="/curriculum"
                className="btn btn-orange withradius secbtn withicon"
              >
                {t("home.news_more_button")}{" "}
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </ScrollToTopLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Who;
