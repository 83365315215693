const Program = ({ concert, t }) => {
  return (
    <>
      {concert.program.length > 0 && (
        <>
          <h4 className="text-capitalize titlenotaligned mt-5">
            {t("concerts.program")}
          </h4>

          {concert.program.map(({ composer, piece, id }) => (
            <div className="row" key={id}>
              <div className="col col-lg-5">
                <h5 className="colorok text-capitalize">{composer}</h5>
              </div>
              <div className="col">
                <h5 className="colorok">{piece}</h5>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default Program;
