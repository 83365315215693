import React from "react";
import { useTranslation } from "react-i18next";
import ScrollToTopLink from "../ScrollToTopLink";

const Menu = ({ data, submenu, handleSubmenuItemTouch }) => {
  const { t } = useTranslation();
  const submenuData = data[submenu] || []; // Extract submenu data or default to an empty array

  return (
    <>
      {submenuData.map(({ name, to, id }) => (
        <li key={id}>
          <ScrollToTopLink
            onClick={() => handleSubmenuItemTouch()}
            className={submenu === "footer" ? "" : "dropdown-item"}
            to={to}
            istoplevel={true.toString()} // Pass true for top-level items
          >
            {t(`nav.${name}`)}
          </ScrollToTopLink>
        </li>
      ))}
    </>
  );
};

export default Menu;
