import { useTranslation } from "react-i18next";

const Welcome = ({ page }) => {
  const { t } = useTranslation();

  const getTitle = () => {
    switch (page) {
      case "/contacts":
        return t("nav.contacts");
      case "/privacy":
        return t("nav.privacy");
      case "/support":
        return t("nav.support");
      case "/transparency":
        return t("nav.transparency");
      case "/concerts":
        return t("concerts.calendar");
      case "/archive":
        return t("nav.archive");
      case "/curriculum":
        return t("nav.cv");
      case "/about":
        return t("nav.about");
      case "/conductor":
        return t("nav.conductor");
      case "/soloists":
        return t("nav.soloists");
      case "/dedications":
        return t("nav.dedications");
      case "/reviews":
        return t("nav.reviews");
      case "/news":
        return t("nav.news");
      case "/discography":
        return t("nav.discography");
      case "/magazines":
        return t("nav.magazines");
      case "/testimonials":
        return t("nav.testimonials");
      case "/first_executions":
        return t("nav.first_executions");
      default:
        return <>404 - {t("notfound.page_not_found")}</>;
    }
  };

  return (
    <section className="hero">
      <div className="magnifier grd3"></div>
      <div className="container concertintro text-left">
        <h2 className="text-capitalize text-center">{getTitle()}</h2>
        <hr className="invis1" />
      </div>
    </section>
  );
};

export default Welcome;
