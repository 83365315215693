import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons";

const BuyButton = ({ href, text }) => {
  return (
    <Link to={href} className="text-capitalize buybutton">
      <FontAwesomeIcon icon={faMoneyBillAlt} /> {text}
    </Link>
  );
};

export default BuyButton;
