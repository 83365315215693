import React from "react";
import ImgTextCard from "../cards/ImgTextCard";

const Discography = ({ cds, renderBody }) => {
  return (
    <section className="section">
      <div className="container">
        {cds.map((cd) => (
          <ImgTextCard
            key={cd.id}
            id={cd.id}
            title={cd.title}
            img={cd.img}
            details={cd.details}
            buy_link={cd.buy_link}
            img_width={50}
            text_instead_of_buy_link={cd.text_instead_of_buy_link}
            renderBody={renderBody}
          />
        ))}
      </div>
    </section>
  );
};

export default Discography;
