import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";

const NewsButton = ({ url, text }) => {
  return url ? (
    <div className="text-center mt-3">
      <Link to={url} className="button-download">
        <FontAwesomeIcon icon={faFileInvoice} className="me-3" /> {text}
      </Link>
    </div>
  ) : null;
};

export default NewsButton;
