const Sponsors = ({ sponsor_img, sponsor_img_width, t }) => {
  return (
    <>
      {sponsor_img !== "" && (
        <div className="container mt-5">
          <h4 className="text-capitalize titlenotaligned">
            {t("concerts.sponsor")}
          </h4>
          <div className="text-center">
            <img
              src={sponsor_img}
              alt="Sponsors"
              width={sponsor_img_width}
              className="rounded_img"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Sponsors;
