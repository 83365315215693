import NewsCard from "../cards/news/NewsCard";

const News = ({ news, formatDate }) => {
  return (
    <section className="section">
      <div className="container">
        {news.map((newsItem) => (
          <NewsCard
            key={newsItem.id}
            newsItem={newsItem}
            formatDate={formatDate}
          />
        ))}
      </div>
    </section>
  );
};

export default News;
