import React from "react";
import { useTranslation } from "react-i18next";

const Support = ({ renderBody, sponsors }) => {
  const { t } = useTranslation();
  return (
    <section className="section">
      <div className="container">
        <div className="mb-5">{renderBody(t("support.intro"))}</div>
        {sponsors.map((sponsor) => (
          <div className="row sponsorblock" key={sponsor.id}>
            <div className="col-sm text-center">
              <img src={sponsor.src} alt={sponsor.name} width={"40%"} />
            </div>
            <div className="col-sm uppercasebold2 sponsorname">
              {sponsor.name}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Support;
