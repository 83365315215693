import { useTranslation } from "react-i18next";
import magazines from "../../data/magazines.json";
import Card from "../cards/ImgTextCard";

const Magazines = ({ renderBody }) => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <h3 className="newstitle text-capitalize mb-5">
        {t("magazines.magazines")}
      </h3>
      {magazines.map((magazine) => (
        <Card
          key={magazine.id}
          id={magazine.id}
          title={magazine.title}
          img={magazine.img}
          details={magazine.details}
          img_width={40}
          renderBody={renderBody}
        />
      ))}
    </div>
  );
};

export default Magazines;
