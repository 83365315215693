import { useTranslation } from "react-i18next";
import data from "../../data/first.json";
import FirstCard from "./FirstCard";

const FirstExecutions = ({ renderBody }) => {
  const { t } = useTranslation();
  const executions = data[0].executions; // Access the executions array
  const recordings = data[0].recordings; // Access the recordings array
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="text-center">
            <h3 className="text-capitalize newstitle mb-5">
              {t("first_exe.executions")}
            </h3>
            <FirstCard input={executions} renderBody={renderBody} />
          </div>
          <div className="text-center mt-5">
            <h3 className="text-capitalize newstitle mb-3">
              {t("first_exe.recordings")}
            </h3>
            <FirstCard input={recordings} renderBody={renderBody} />
          </div>
        </div>
      </section>
    </>
  );
};

export default FirstExecutions;
